export const StatePayment = {
    'amo' : '',
    'period': '',
    'sum': '',
    'sum_minus': 0,
    'consumptions': '',
    'comment': '',
    'project': '',
    'category': '0',
    'site': '',
    'payer': '',
    'projectType': '1',
    'daysType': '1',
    'daysProject': '',
    'workers': [
        {
            'manager': 'Иван Ярославцев',
            'seller': 'Иван Ярославцев',
            'id_payment_percent_sell': '',
            'id_payment_percent_manager': '',
            'id_payment_percent': '',
            'name': 'Продажа',
            'total': '',
            'percent': ''
        },
    ],
    'full_sum': '',
    'money_to': '',
    'from': '',
    'status': 0,
    'sent_to_planfix': 0,
    'oldProject': 0,
    'type': 'income',
    'comission': '',
    "salaryDate": '',
};
